/**
 * Created by nick on 1/9/2020.
 */
import React from "react"
import {Link} from "gatsby"

import QuestionLayout from "../components/questionlayout"
import Helmet from 'react-helmet'
import SiteInfo from "../components/siteinformation"

const Questions1 = () => (
    <QuestionLayout>
        <Helmet>
            <script src='/landerview.js' type="text/javascript"/>
        </Helmet>

        <div className="container">
{/*
            <div className="question-header question-header-one is-vertical-center content-centered">
                Take This Short Quiz to find out
            </div>
            <div className="question">
                <h3>Do you drink coffee throughout the day?</h3>
            </div>
            <div className="progress-image">
                <progress className="progress is-primary" value="15" max="100"></progress>
            </div>
            <div className="columns is-centered">

                <div className="questions column is-half-desktop">
                    <a href="/questions2/?answer=1" className="quiz-button button"><i
                        className="fa fa-square-o has-background-white"></i>&nbsp;Yes 1-2
                        cups</a>
                    <a href="/questions2/?answer=2" className="quiz-button button"><i
                        className="fa fa-square-o has-background-white"></i>&nbsp;Yes 3-4
                        cups</a>
                    <a href="/questions2/?answer=3" className="quiz-button button"><i
                        className="fa fa-square-o has-background-white"></i>&nbsp;Yes 5+
                        cups</a>
                    <a href="/questions2/?answer=4" className="quiz-button button"><i
                        className="fa fa-square-o has-background-white"></i>&nbsp;No</a>
                </div>

            </div>
*/}

            <div className="content-spacer">
                . <br/>

            </div>
        </div>

    </QuestionLayout>
)

export default Questions1



